
(function ($) {
    var plugin = this;
    plugin.settings = {};
    plugin.searchString = "";
    plugin.totalPages = 0;
    plugin.loadFacets = true;
    plugin.loadContent = true;
    plugin.groupClicked = false;
    plugin.megaMenuFilter = false;
    plugin.settings.groupName = "";
    plugin.settings.listUrl = "";
    plugin.settings.listType = "catcompletepeople";


    plugin.pagingInfo = {};
    plugin.pagingInfo.pageSize = 5;
    plugin.pagingInfo.currentPage = 1;
    plugin.pagingInfo.isLoadPagination = true;

    plugin.pagingInfo.pagingLastCount = "";
    plugin.pagingInfo.pagingFirstCount = "";
    plugin.pagingInfo.pagingNextCount = "";
    plugin.pagingInfo.pagingPreviousCount = "";

    plugin.pagingInfo.isLastClick = false;
    plugin.pagingInfo.isFirstClick = false;
    plugin.pagingInfo.isNextClick = false;
    plugin.pagingInfo.isPreviousClick = false;
    plugin.pagingInfo.isViewAllClick = false;
    plugin.pagingInfo.isBackToPagingClick = false;
    plugin.processingRequest = false;
    plugin.datasource = {};
    $(document).on("click", ".pageno", function () {
        $(".pageno").removeClass("selected");
        $(this).addClass("selected");
    });
    $(".pagActive").attr('href', 'javascript:void(0);');

    $.peopleListing = function (element, options) {
        /* defaults */
        var defaults = {
            pageNumber: "#pageNum",
            pageSize: "10",
            resultsontainer: "#peopleResult",
            itemId: "",
            filtersDiv: "#searchFilters",
            loaderDiv: "#divProcessingPeopleResult",

            lastAlphaNav: '',
            pagingContainer: "#pagingContainerPeople",
            pageHeader: "#peopleListingHeader",
            contactFormUrl: '',
            isInternalBioRequest: ''

        };

        function ResetPagingInfo() {
            plugin.pagingInfo.pageSize = 5;
            plugin.pagingInfo.currentPage = 1;
            plugin.pagingInfo.isLoadPagination = true;
            plugin.pagingInfo.pagingLastCount = "";
            plugin.pagingInfo.pagingFirstCount = "";
            plugin.pagingInfo.pagingNextCount = "";
            plugin.pagingInfo.pagingPreviousCount = "";
            plugin.pagingInfo.isLastClick = false;
            plugin.pagingInfo.isFirstClick = false;
            plugin.pagingInfo.isNextClick = false;
            plugin.pagingInfo.isPreviousClick = false;
            plugin.pagingInfo.isViewAllClick = false;

        };

        var $element = $(element), element = element;


        /* models */
        var group = function (personName, personImage, personDesignation, personURL, personService, personEmail, personPhone, personOfficeDisplay, personAlternateBio, personAltBioLangVersionUrls, searchItemID) {
            this.PersonName = personName,
            this.PersonImage = personImage,
            this.PersonDesignation = personDesignation,
            this.PersonURL = personURL,
            this.PersonService = personService,
            this.PersonEmail = personEmail,
            this.Mailto = "mailto:" + personEmail,
            this.PersonPhone = personPhone,
            this.PersonOfficeDisplay = personOfficeDisplay,
            this.PersonBiosDisplay = personAlternateBio,
            this.PersonAltBioLangVersionUrls = personAltBioLangVersionUrls,
            this.SearchItemID = "/api/custom/VCard/DownloadVCard?itemId=" + decodeURIComponent(searchItemID)


        }

        var totalRecordCount = function (n) {
            this.totalRecord = n;
        }
        var facet = function (n, c) {
            this.count = c,
            this.name = n;
        }
        var pagefacet = function (n, c, isLarge) {
            this.pageNo = n;
            this.applyCss = c;
            this.isLargeNumber = isLarge;
        }
        /* init function */
        plugin.init = function () {

            plugin.settings = $.extend({}, defaults, options);

            plugin.datasource.vm = {
                facets: ko.observableArray([]),
                searchGroups: ko.observableArray([]),
                facetGroups: ko.observableArray([]),
                total: ko.observable(""),
                /* if there spell check is true */
                showResults: ko.observable(""),
                searchText: ko.observable(""),
                /* for paging */
                pagingFacets: ko.observableArray([]),
                TotalRecordCounts: ko.observableArray([]),
                pageLastNo: ko.observable(""),
                pageFirstNo: ko.observable(""),
                loadPageData: plugin.loadPagingResult,
                PageFirstClick: plugin.pgFirstclick,
                PageLastClick: plugin.pgLastclick,
                PageNextClick: plugin.pgNextclick,
                PagePreviousClick: plugin.pgPreviousclick,
                PageViewAllClick: plugin.pgViewAllClick,
                PageBackToPaginationClick: plugin.pgBackToPaginationClick
            };

            $(document).ready(function ($) {
                ko.applyBindings(plugin.datasource.vm, $("#peopleListing")[0]);
                $('#biopeople-search').on('keypress', function (event) {
                    if (event.which == '13') {
                        $(".tile-wrapper").hide();
                        ResetPagingInfo();
                        plugin.settings.groupName = "";
                        $(".filter-search-head").hide();
                        //if ($(window).width() < 480) {
                        //    $(".result-found-res").hide();
                        //}
                        plugin.datasource.vm.searchGroups.removeAll();
                        //plugin.loadFacets = false;
                        $('#btnSearchMain').focus();
                        $(".people-search-result-wrapper").show();
                        var isbioListingPage = true;
                        if (plugin.settings.isInternalBioRequest) {
                            if (plugin.settings.isInternalBioRequest == "true") {
                                isbioListingPage = false;
                                plugin.loadSearchResults(1, '');
                            }
                        }
                        if (isbioListingPage) {
                            window.location = getResultPageUrl(1, '');
                            return;
                        }

                        $(".ui-autocomplete").hide();
                        $('#people-search-result-inner-wraper').focus();

                        $(".peopSearSect .enterBtn input.search-rtn").attr("value", "Search");
                        $(".peopSearSect .enterBtn input.clr-search").css("display", "block");
                        return false;

                    }
                });
            });

            ResetPagingInfo();
            var isFilter = false;

            if ($(window).width() < 480 || $(window).height() < 480) {
                $('#divPageSizeWeb').hide();
                $('#divPageSizeMob').show();
                $('#oldSelectedPageSize').val($('#ddlPageSize_bioMob').val());

            }
            $('.resetLink').trigger('click');
            $(".no-result-found").hide();


            var qsLawschoolVal = decodeURIComponent($.urlParam('LawSchoolValue'));
            if (qsLawschoolVal != null && qsLawschoolVal != undefined && qsLawschoolVal != '0') {
                isFilter = true;
                $("#school-search").val(qsLawschoolVal);

            }

            var filter1txt = getParamValueFrmKey("filter1Text");
            var filter1Name = getParamValueFrmKey("filter1Name");
            var filter1ID = getParamValueFrmKey("filter1ID");
            var filter1Value = getParamValueFrmKey("filter1Value");

            if (filter1txt && filter1txt != '') {
                isFilter = true;
                $("#biosector-search").val(decodeURIComponent(filter1txt));
            }

            if (filter1txt && filter1Name && filter1ID) {
                isFilter = true;
                $("#biosector-search").attr('filter-indexname', filter1Name);
                $("#biosector-search").attr('filter-indexid', filter1ID);
                $("#biosector-search").attr('filter-indexvalue', decodeURIComponent(filter1Value));

            }

            var filter2ID = getParamValueFrmKey("filter2ID");
            if (filter2ID) {
                isFilter = true;
                $("#allPosition").val(decodeURIComponent(filter2ID));
                var userText = $("#allPosition option:selected").text();
                $(".allPositionddl .main #ulallPosition .stylish-select-left .stylish-select-right").text(userText);

            }

            var filter3ID = getParamValueFrmKey("filter3ID");
            if (filter3ID) {
                isFilter = true;
                $("#allOfficeLocation").val(decodeURIComponent(filter3ID));
                var userText = $("#allOfficeLocation option:selected").text();
                $(".allOfficeLocationddl .main #ulallOfficeLocation .stylish-select-left .stylish-select-right").text(userText);
            }
            var filter4Text = getParamValueFrmKey("filter4Text");
            if (filter4Text) {
                isFilter = true;
                $("#biopeople-search").val(decodeURIComponent(filter4Text));

            }


            if (isFilter) {
                plugin.settings.groupName = "";
                $(".filter-search-head").hide();

                plugin.datasource.vm.searchGroups.removeAll();

                removePeopleAllFillters();
                RemoveSelection();

                $(".ui-autocomplete").hide();
                $('#people-search-result-inner-wraper').focus();
                $('.people-search-result-wrapper').css("display", "block");
                $(".peopSearSect .enterBtn input.search-rtn").attr("value", "Search");
                $(".peopSearSect .enterBtn input.clr-search").css("display", "block");

                if ($(window).width() < 480) {
                    $('html, body').animate({
                        'scrollTop': $('.people-search-result-wrapper').offset().top - 170
                    });
                } else {
                    $('html, body').animate({
                        'scrollTop': $('.people-search-result-wrapper').offset().top - 230
                    });
                }


            }

            // }, 100);
            plugin.loadSearchResults(1, "", isFilter);

            $("#custom4").change(function () {
                var oldSelectedval = $('#oldSelectedval').val();
                if (oldSelectedval != $('#custom4').val()) {
                    $('#oldSelectedval').val($('#custom4').val());
                    $(".filter-search-head").hide();

                    plugin.datasource.vm.searchGroups.removeAll();

                    var viewAllValue = plugin.pagingInfo.isViewAllClick;
                    ResetPagingInfo();
                    plugin.pagingInfo.isViewAllClick = viewAllValue;
                    plugin.loadSearchResults(1, '');
                }
            });

            $("#allPosition").change(function () {
                var ddlName = 'allPosition';
                if ($('#' + ddlName).prop('selectedIndex') == 0) {
                    $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").css('color', '#7f7f7f');
                }
                else {
                    $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").css('color', '#000000');
                }
            });

            $("#allOfficeLocation").change(function () {
                var ddlName = 'allOfficeLocation';
                if ($('#' + ddlName).prop('selectedIndex') == 0) {
                    $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").css('color', '#7f7f7f');
                }
                else {
                    $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").css('color', '#000000');
                }
            });

            $("#ddlPageSize").change(function () {
                var oldSelectedval = $('#oldSelectedPageSize').val();
                if (oldSelectedval != $('#ddlPageSize').val()) {
                    $('#oldSelectedPageSize').val($('#ddlPageSize').val());
                    $(".filter-search-head").hide();

                    plugin.datasource.vm.searchGroups.removeAll();

                    var viewAllValue = plugin.pagingInfo.isViewAllClick;
                    ResetPagingInfo();
                    plugin.pagingInfo.isViewAllClick = viewAllValue;
                    plugin.loadSearchResults(1, '');
                }
            });

            $("#ddlPageSize_bioMob").change(function () {
                var oldSelectedval = $('#oldSelectedPageSize').val();
                if (oldSelectedval != $('#ddlPageSize_bioMob').val()) {
                    $('#oldSelectedPageSize').val($('#ddlPageSize_bioMob').val());
                    $(".filter-search-head").hide();

                    plugin.datasource.vm.searchGroups.removeAll();

                    var viewAllValue = plugin.pagingInfo.isViewAllClick;
                    ResetPagingInfo();
                    plugin.pagingInfo.isViewAllClick = viewAllValue;
                    plugin.loadSearchResults(1, '');
                }
            });

            //Ends


            /*Removing function search filters*/
            $('.allRemove').click(function () {
                var title = $(this).attr('data-title');
                $('input[name="' + title + '"]').attr('checked', false);
                $(this).next().next('ul').find('li').remove();
                $(this).parent('.filterSection').hide();
                ResetPagingInfo();
                //plugin.loadFacets = false;
                plugin.datasource.vm.searchGroups.removeAll();
                plugin.loadSearchResults(1, "");
            });


            if (megaMenuFilter != "") {
                $(".peopleOptionChkFilters").each(function () {
                    if (megaMenuFilter.indexOf($.trim($(this).val())) == 0) {
                        this.checked = true;
                        plugin.megaMenuFilter = true;
                        checkFiltering(this.name, this);
                        plugin.loadSearchResults(1, '');
                    }

                });
            }

            $('.searchFilterBox').on('click', 'span.removePartFil', function () {
                var filLiText = $(this).parent().find('em').text();
                var title = $(this).attr('data-title');
                var parentId = '#' + $(this).parentsUntil('.filterSection').parent().parent().attr('id');
                $('input[name="' + title + '"][alt="' + filLiText + '"]').attr('checked', false);
                $(this).parent('li').remove();
                checkCondition(title, parentId);
                ResetPagingInfo();

                plugin.datasource.vm.searchGroups.removeAll();
                plugin.loadSearchResults(1, "");
            });
            /*end*/

        }

        $("#btnSearchMain").click(function (e) {
            $(".filter-search-head").hide();
            //if ($(window).width() < 480) {
            //    $(".result-found-res").hide();
            //}
            $(".no-result-found").hide();
            plugin.datasource.vm.searchGroups.removeAll();
            $('#btnSearchMain').focus();
            plugin.settings.groupName = "";
            ResetPagingInfo();


            var isbioListingPage = true;
            if (plugin.settings.isInternalBioRequest) {
                if (plugin.settings.isInternalBioRequest == "true") {
                    isbioListingPage = false;
                    plugin.loadSearchResults(1, '');
                }
            }
            if (isbioListingPage) {
                window.location = getResultPageUrl(1, '');
                return;
            }


        });


        $(document).on('click', "a.alphabet", function () {
            var data = $.trim($(this).text()).toLowerCase();
            if (data) {
                $('#biopeople-search').val($('#biopeople-search').attr('defaultvalue'));
                $("a.alphabet").removeClass("selected");
                $(this).addClass("selected");

                $(".people-search-result-wrapper").show();
                $(".peopSearSect .enterBtn input.clr-search").css("display", "block");
                $(".tile-wrapper").hide();
                $(".filter-search-head").hide();
                //if ($(window).width() < 480) {
                //    $(".result-found-res").hide();
                //}
                $(".no-result-found").hide();
                plugin.datasource.vm.searchGroups.removeAll();
                //plugin.loadFacets = false;
                ResetPagingInfo();
                plugin.groupClicked = true;


                removePeopleAllFillters();


                //clearAutoSuggestBox();

                $('#backToPage_Bio').hide();
                if (data.toLowerCase() == "all") {
                    plugin.settings.groupName = "";
                    plugin.loadSearchResults(1, "");
                    $('#' + 'a' + data).addClass('alphaActive');
                } else {
                    plugin.settings.groupName = data;

                    var isbioListingPage = true;
                    if (plugin.settings.isInternalBioRequest) {
                        if (plugin.settings.isInternalBioRequest == "true") {
                            isbioListingPage = false;
                            plugin.loadSearchResults(1, data);
                        }
                    }
                    if (isbioListingPage) {
                        window.location = getResultPageUrl(1, data);
                        return;
                    }
                }

                $("html, body").animate({ scrollTop: $('#peopleListing').offset().top });
            }
        });

        plugin.loadPagingResult = function (data, event) {
            $(".filter-search-head").hide();

            $(".no-result-found").hide();
            plugin.datasource.vm.searchGroups.removeAll();
            plugin.pagingInfo.isLoadPagination = false;

            plugin.pagingInfo.isFirstClick = false;
            plugin.pagingInfo.isLastClick = false;
            plugin.pagingInfo.isNextClick = false;
            plugin.pagingInfo.isPreviousClick = false;
            plugin.pagingInfo.currentPage = data.pageNo;

            var pageSize = $('#ddlPageSize').val();
            var loadIndex = ((data.pageNo - 1) * pageSize) + 1; //get index of first entry on next page

            plugin.loadSearchResults(loadIndex, "");


        };


        // for first click
        plugin.pgFirstclick = function (data, event) {
            $(".filter-search-head").hide();

            $(".no-result-found").hide();
            plugin.datasource.vm.searchGroups.removeAll();
            plugin.pagingInfo.isLoadPagination = true;

            plugin.pagingInfo.isFirstClick = true;
            plugin.pagingInfo.isLastClick = false;
            plugin.pagingInfo.isNextClick = false;
            plugin.pagingInfo.isPreviousClick = false;
            plugin.pagingInfo.currentPage = plugin.pagingInfo.pagingFirstCount;
            plugin.loadSearchResults(plugin.pagingInfo.pagingFirstCount, "");
        };

        // for last click
        plugin.pgLastclick = function (data, event) {
            $(".filter-search-head").hide();

            $(".no-result-found").hide();
            plugin.datasource.vm.searchGroups.removeAll();
            plugin.pagingInfo.isLoadPagination = true;

            plugin.pagingInfo.isLastClick = true;
            plugin.pagingInfo.isFirstClick = false;
            plugin.pagingInfo.isNextClick = false;
            plugin.pagingInfo.isPreviousClick = false;
            plugin.pagingInfo.currentPage = plugin.pagingInfo.pagingLastCount;
            plugin.loadSearchResults(plugin.pagingInfo.pagingLastCount, "");
        };

        // for Next click
        plugin.pgNextclick = function (data, event) {
            $(".filter-search-head").hide();

            $(".no-result-found").hide();
            plugin.datasource.vm.searchGroups.removeAll();
            plugin.pagingInfo.isLoadPagination = true;

            plugin.pagingInfo.isNextClick = true;
            plugin.pagingInfo.isLastClick = false;
            plugin.pagingInfo.isFirstClick = false;
            plugin.pagingInfo.isPreviousClick = false;

            plugin.pagingInfo.currentPage = plugin.pagingInfo.pagingNextCount;
            plugin.loadSearchResults(plugin.pagingInfo.pagingNextCount, "");
        };

        // for Previous click
        plugin.pgPreviousclick = function (data, event) {
            $(".filter-search-head").hide();

            $(".no-result-found").hide();
            plugin.datasource.vm.searchGroups.removeAll();

            plugin.pagingInfo.isLoadPagination = true;
            plugin.pagingInfo.isNextClick = false;
            plugin.pagingInfo.isLastClick = false;
            plugin.pagingInfo.isFirstClick = false;
            plugin.pagingInfo.isPreviousClick = true;
            plugin.pagingInfo.currentPage = plugin.pagingInfo.pagingPreviousCount;
            plugin.loadSearchResults(plugin.pagingInfo.pagingPreviousCount, "");
        };



        plugin.pgViewAllClick = function (data, event) {
            $(".filter-search-head").hide();

            $(".no-result-found").hide();
            plugin.datasource.vm.searchGroups.removeAll();

            ResetPagingInfo();
            plugin.pagingInfo.isViewAllClick = true;
            plugin.loadSearchResults(1, "");
        };

        plugin.pgBackToPaginationClick = function (data, event) {
            $(".filter-search-head").hide();

            $(".no-result-found").hide();
            plugin.datasource.vm.searchGroups.removeAll();
            ResetPagingInfo();
            plugin.loadSearchResults(1, "");
        };



        plugin.loadSearchResults = function (currentPagenum, groupName, loadResults) {
            if (!plugin.processingRequest) {
                plugin.processingRequest = true;
                $(plugin.settings.loaderDiv).show();
                $(plugin.settings.pagingContainer).hide();
                $(plugin.settings.pageHeader).hide();

                $.getJSON(getServiceUrl(currentPagenum, groupName), function (data) {
                    $("#divSearchGroups").empty();
                    if (plugin.loadFacets) {
                        var alphaString = '';
                        plugin.loadFacets = false;

                        var enableAll = false;
                        if (data.SearchGroups != null && data.SearchGroups.length > 0) {
                            enableAll = true;
                        }

                        $.each(data.Facets, function (i, f) {

                            if (f.AggregateCount > 0) {
                                var cssClass = '';
                                if (f.Name.toLowerCase() == "all" && groupName == '') {
                                    cssClass = 'alphabet alphaActive';
                                } else if (groupName != '' && f.Name.toLowerCase() == groupName) {
                                    cssClass = 'alphabet alphaActive';
                                } else {
                                    cssClass = 'alphabet';
                                }
                                alphaString = alphaString + "<div><a title='" + f.Name.toUpperCase() + "' class='" + cssClass + "' id='a" + f.Name + "' href='javascript:void(0);' aria-label='" + f.Name.toUpperCase() + "'> " + f.Name.toUpperCase() + " </a></div>";
                            } else {
                                alphaString = alphaString + "<div><a title='" + f.Name.toUpperCase() + "' class='inactive' href='javascript:void(0); aria-label='" + f.Name.toUpperCase() + "'> " + f.Name.toUpperCase() + " </a></div>";

                            }
                        });
                        $("#divAlphabets").html(alphaString);
                        $(".alphaSearch").owlCarousel({
                            items: 26,
                            loop: false,
                            autoplay: false,
                            mouseDrag: false,
                            dots: false,
                            nav: true,
                            navText: false,
                            responsive: {
                                0: { items: 3 },
                                320: { items: 5 },
                                480: { items: 10 },
                                640: { items: 15 },
                                768: { items: 26 }
                            }
                        });


                    }
                    if (loadResults == false) {
                        $(plugin.settings.loaderDiv).hide();
                        plugin.processingRequest = false;
                        return;
                    }
                    var resultCount = 0;
                    if (data.SearchGroups != null && data.SearchGroups.length > 0) {
                        resultCount = data.SearchGroups.length;
                    }


                    if (data.SearchGroups == null || data.SearchGroups.length == 0) {
                        plugin.totalPages = 0;
                        if ($('#peopleSorting>.resOrdDetail')) {
                            $('#peopleSorting>.resOrdDetail').hide();
                        }
                        plugin.datasource.vm.searchGroups.removeAll();
                        $(".no-result-found").show();

                        $(".people-search-result-wrapper").hide();
                        $(plugin.settings.pagingContainer).hide();
                        $(plugin.settings.pageHeader).hide();
                        $(plugin.settings.loaderDiv).hide();
                        plugin.processingRequest = false;

                        if ($(window).width() < 480) {
                            $('html, body').animate({
                                'scrollTop': $('.no-result-found').offset().top - 170
                            });
                        } else {
                            $('html, body').animate({
                                'scrollTop': $('.no-result-found').offset().top - 230
                            });
                        }
                        return;
                    }
                    if (data.TotalRecords != null) {

                        plugin.datasource.vm.TotalRecordCounts.removeAll();
                        plugin.datasource.vm.TotalRecordCounts.push(new totalRecordCount(data.TotalRecords));

                    }

                    if (data.SearchGroups.length > 0) {
                        $(".no-result-found").hide();
                        $(".filter-search-head").show();

                        if (data.PersonPaging.IsPaginationVisible) {

                            $(plugin.settings.pagingContainer).show();


                            if (plugin.pagingInfo.isLoadPagination) {
                                //if exists then remove first
                                plugin.datasource.vm.pagingFacets.removeAll();
                                $.each(data.PersonPaging.PageNumbersFacets, function (i, f) {
                                    var isLarge = false;
                                    if (f > 99) {
                                        isLarge = true;
                                    }
                                    if (plugin.pagingInfo.currentPage == f) {
                                        plugin.datasource.vm.pagingFacets.push(new pagefacet(f, 2, isLarge));
                                    } else {
                                        plugin.datasource.vm.pagingFacets.push(new pagefacet(f, 0, isLarge));
                                    }

                                });

                                //set last and first page number
                                plugin.pagingInfo.pagingLastCount = data.PersonPaging.PageLastNumber;
                                plugin.pagingInfo.pagingFirstCount = data.PersonPaging.PageFirstNumber;

                                //set last and previous index
                                plugin.pagingInfo.pagingNextCount = data.PersonPaging.PageNextNumber;
                                plugin.pagingInfo.pagingPreviousCount = data.PersonPaging.PagePreviousNumber;

                                //for set next and previous cursor

                                if (data.PersonPaging.IsNextCursorVisible) {
                                    $('#pagNextBtn').show();
                                    $('#pagLastBtn').show();
                                } else {
                                    $('#pagNextBtn').hide();
                                    $('#pagLastBtn').hide();
                                }

                                if (data.PersonPaging.IsPreviousCursorVisible) {
                                    $('#pagFirstBtn').show();
                                    $('#pagPrevBtn').show();
                                } else {
                                    $('#pagFirstBtn').hide();
                                    $('#pagPrevBtn').hide();
                                }
                            }

                        } else {
                            $(plugin.settings.loaderDiv).hide();
                            $(plugin.settings.pagingContainer).hide();

                        }
                        var count = 1;
                        var css = 'peopleListingBox';
                        $.each(data.SearchGroups, function (i, f) {
                            if (data.SearchGroups.length == count)
                                css = "peopleListingBox last";
                            var personUrlCustom = f.PersonURL;
                            if (plugin.settings.isInternalBioRequest) {
                                if (plugin.settings.isInternalBioRequest == "true") {
                                    
                                    // TODO: manual appending of query string params is not recommended
                                    // Do this this instead:
                                    // var personURL = new URL(f.PersonURL, window.location.origin);
                                    // personURL.searchParams.set('p', '1');
                                    // personURL.searchParams.set('altbio', 'true');

                                    personUrlCustom = f.PersonURL + "?p=1&altbio=true";
                                    f.PersonAltBioLangVersionUrls = f.PersonAltBioLangVersionUrls.map(function (obj) { obj.Url += "?p=1&altbio=true"; return obj });
                                }
                            }
                            var personOfficetxt = f.PersonOfficeDisplay;

                            var personOfficeDisplaytxt = [];
                            if (personOfficetxt) {
                                $.each(personOfficetxt, function (index, value) {
                                    //   alert(index + ": " + value);
                                    var tempVar = value.split('||');
                                    if (tempVar) {
                                        personOfficeDisplaytxt.push(tempVar[0]);
                                    }
                                });
                            }
                            plugin.datasource.vm.searchGroups.push(new group(f.name_for_search, f.PersonImage, f.PersonDesignation, personUrlCustom, f.PersonService, f.Email, f.Phone, personOfficeDisplaytxt, f.PersonAlternateBio, f.PersonAltBioLangVersionUrls, f.Uri.Path));
                            count++;
                        });

                        $(plugin.settings.loaderDiv).hide();
                        //$('.people-search-result-inner-wraper div li:nth-child(odd)').addClass('bg-light-grey');
                        $('.people-search-result-inner-wraper >ul> div >li:nth-of-type(odd)').addClass('bg-light-grey');
                        $(".people-search-result-inner-wraper ul li div.contact ul li").removeClass("bg-light-grey");
                        $(".people-search-result-inner-wraper ul li div.details ul li").removeClass("bg-light-grey");
                    }
                    plugin.processingRequest = false;
                    if ($(window).width() < 480) {
                        $('html, body').animate({
                            'scrollTop': $('.people-search-result-wrapper').offset().top - 170
                        });
                    } else {
                        $('html, body').animate({
                            'scrollTop': $('.people-search-result-wrapper').offset().top - 230
                        });
                    }


                });
            }

        }

        var getResultPageUrl = function (pagenum, groupName) {

            var searchString = '';
            
            var url = $("#btnSearchMain").data("result-page") + "?";
            var query = [];

            var isGroup = false;
            query.push('param_sitecontentcategory=OUR PEOPLE');

            if (groupName) {
                isGroup = true;
                query.push("groupName=" + groupName);
            }
            else if (plugin.settings.groupName) {
                isGroup = true;
                query.push("groupName=" + plugin.settings.groupName);
            }
            if (!isGroup) {
                if ($('#biopeople-search') != null && $('#biopeople-search').val() != null && $('#biopeople-search').val().toLowerCase() != $('#biopeople-search').attr('defaultvalue').toLowerCase()) {
                    searchString = $('#biopeople-search').val();
                }
                else if ($('#people-search') != null && $('#people-search').val() != null && $('#people-search').val().toLowerCase() != $('#people-search').attr('defaultvalue').toLowerCase()) {
                    searchString = $('#people-search').val();
                }
                if (searchString) {
                    query.push("SearchString=" + encodeURIComponent(searchString));
                }

                if (plugin.megaMenuFilter) {
                    query.push("megaMenuFilter=true");
                }

                var currentGrpString = '';
                var filterGrp = ''
                var selectedValOffice = $('.allOfficeLocationddl select').val();
                var subCatInfoVal = '';
                var subCatTextVal = '';
                if (selectedValOffice != "") {
                    filterGrp = 'Office'
                    currentGrpString = selectedValOffice;
                    var userTextPagesize = $("#allOfficeLocation option:selected").text();
                    var subCatText = 'Office : ' + userTextPagesize;
                    subCatInfoVal = "Office," + selectedValOffice;
                    subCatTextVal = encodeURIComponent(subCatText);
                    
                }

                if ($('.allPositionddl select').val()) {
                    var selectedValPosition = $('.allPositionddl select').val();
                    if (selectedValPosition != "") {
                        var key = $('.allPositionddl select').find(':selected').data('category');
                        query.push("designation_s=" + selectedValPosition);
                    }
                }
                var practiceFieldID = '#biosector-search';
                if ($(practiceFieldID).val()) {
                    var filterID = $(practiceFieldID).attr('filter-IndexId');
                    var filterName = $(practiceFieldID).attr('filter-IndexName');
                    var filterValue = $(practiceFieldID).attr('filter-IndexValue');
                    var filterParentID = $(practiceFieldID).attr('filter-IndexParentID');
                    var fieldtextValue = $(practiceFieldID).val();

                    if (fieldtextValue && filterValue && $.trim(fieldtextValue) != '' && $.trim(fieldtextValue) == $.trim(filterValue)) {
                        var subCategoryTypeClickSubCat = '';
                        var subCatInfoSubCat = '';
                        var subCatTextSubCat = '';
                        switch (filterName) {
                            case 'param_sectors_sm':
                                currentGrpString = filterID;
                                filterGrp = 'Sector';
                                subCategoryTypeClickSubCat = 'isSectorClick';
                                subCatInfoSubCat = 'Sector,' + filterID;
                                subCatTextSubCat = 'Sector : ' + filterValue.toUpperCase();
                                break;
                            case 'param_practices_sm':
                                currentGrpString = filterID;
                                filterGrp = 'Service';
                                subCategoryTypeClickSubCat = 'isServiceClick';
                                subCatInfoSubCat = 'Service,' + filterID;
                                subCatTextSubCat = 'Service : ' + filterValue.toUpperCase();
                                break;
                            case 'param_region_sm':
                                currentGrpString = filterID;
                                filterGrp = 'Region';
                                subCategoryTypeClickSubCat = 'isRegionClick';
                                subCatInfoSubCat = 'Region,' + filterID;
                                subCatTextSubCat = 'Region : ' + filterValue.toUpperCase();
                                break;

                            case 'param_sub_sectors_sm':
                                currentGrpString = filterParentID;
                                filterGrp = 'Sector';
                                subCategoryTypeClickSubCat = 'isSectorClick';
                                query.push("subSectorInfo=" + filterID + ',' + filterParentID);
                                query.push("subSectorText=" + encodeURIComponent(filterValue.toUpperCase()));
                                break;
                            case 'param_sub_practices_sm':
                                currentGrpString = filterParentID;
                                filterGrp = 'Service';
                                subCategoryTypeClickSubCat = 'isServiceClick';
                                query.push("subServiceInfo=" + filterID + ',' + filterParentID);
                                query.push("subServiceText=" + encodeURIComponent(filterValue.toUpperCase()));
                                break;
                            case 'param_sub_region_sm':
                                currentGrpString = filterParentID;
                                filterGrp = 'Region';
                                subCategoryTypeClickSubCat = 'isRegionClick';
                                query.push("subRegionInfo=" + filterID + ',' + filterParentID);
                                query.push("subRegionText=" + encodeURIComponent(filterValue.toUpperCase()));
                                break;
                        }

                        if (subCategoryTypeClickSubCat) {
                            query.push("subCategoryTypeClick=" + subCategoryTypeClickSubCat);
                        }

                        if (subCatInfoSubCat) {
                            if (subCatInfoVal) {
                                subCatInfoVal = subCatInfoVal + ',' + subCatInfoSubCat;
                            }
                            else {
                                subCatInfoVal = subCatInfoSubCat;
                            }
                        }
                        if (subCatTextSubCat) {
                            if (subCatTextVal) {
                                subCatTextVal = subCatTextVal + ',' + encodeURIComponent(subCatTextSubCat);
                            }
                            else {
                                subCatTextVal = encodeURIComponent(subCatTextSubCat);
                            }
                        }
                    }
                    else {

                        if (fieldtextValue && $.trim(fieldtextValue) != '') {
                            var myValLower = $.trim(fieldtextValue).toLowerCase();
                            var defaultMyValLower = $.trim($(practiceFieldID).attr('defaultvalue')).toLowerCase();
                            if (myValLower != defaultMyValLower) {
                                query.push("sectorServiceSearchString=" + encodeURIComponent(myValLower));
                            }
                        }
                    }

                }
                if (subCatInfoVal) {
                    query.push("subCatInfo=" + subCatInfoVal);
                }
                if (subCatTextVal) {
                    query.push("subCatText=" + subCatTextVal);
                }
                

                if (currentGrpString && currentGrpString != '') {
                    query.push("currentGroup=" + currentGrpString);
                }
                if (filterGrp && filterGrp != '') {
                    query.push("filtergroup=" + filterGrp);
                }
                              
                var schoolInput = $('#school-search').val();
                if (schoolInput && $.trim(schoolInput) != '') {
                    var myVal = $.trim(schoolInput).toLowerCase();
                    var defaultMyVal = $('#school-search').attr('defaultvalue').toLowerCase();
                    var schoolFilterIndexVal = $('#school-search').attr('filter-IndexValue');
                    if (myVal != defaultMyVal) {
                        if (schoolFilterIndexVal && $.trim(schoolFilterIndexVal).toLowerCase() == myVal) {
                            query.push("personschool_sm=" + myVal);
                        } else {
                            query.push("schoolsearchstring=" + myVal);
                        }
                    }
                }
                var courtInput = $('#court-search').val();
                if (courtInput && $.trim(courtInput) != '') {
                    var myVal = $.trim(courtInput).toLowerCase();
                    var defaultMyVal = $('#court-search').attr('defaultvalue').toLowerCase();
                    var courtFilterIndexVal = $('#court-search').attr('filter-IndexValue');
                    if (myVal != defaultMyVal) {
                        if (courtFilterIndexVal && $.trim(courtFilterIndexVal).toLowerCase() == myVal) {
                            query.push("personclerkshipcourts_sm=" + myVal);
                        } else {
                            query.push("courtsearchstring=" + myVal);
                        }
                    }
                }
                var judgeInput = $('#judge-search').val();
                if (judgeInput && $.trim(judgeInput) != '') {
                    var myVal = $.trim(judgeInput).toLowerCase();
                    var defaultMyVal = $('#judge-search').attr('defaultvalue').toLowerCase();
                    var judgeFilterIndexVal = $('#judge-search').attr('filter-IndexValue');
                    if (myVal != defaultMyVal) {
                        if (judgeFilterIndexVal && $.trim(judgeFilterIndexVal).toLowerCase() == myVal) {
                            query.push("personclerkshipjudges_sm=" + myVal);
                        } else {
                            query.push("judgesearchstring=" + myVal);
                        }
                    }
                }
               
            } 
            return url + query.join("&");
        };

        var getServiceUrl = function (pagenum, groupName) {

            var searchString = '';
            var globalSearchString = '';
            var url = "/api/custom/biolisting/execute?";
            var query = [];

            if ($('#biopeople-search') != null && $('#biopeople-search').val() != null && $('#biopeople-search').val().toLowerCase() != $('#biopeople-search').attr('defaultvalue').toLowerCase()) {
                searchString = $('#biopeople-search').val();
            }
            else if ($('#people-search') != null && $('#people-search').val() != null && $('#people-search').val().toLowerCase() != $('#people-search').attr('defaultvalue').toLowerCase()) {
                searchString = $('#people-search').val();
            }

            query.push("pagenum=" + pagenum);

            if (plugin.settings.isInternalBioRequest) {
                query.push("isInternalBioRequest=" + plugin.settings.isInternalBioRequest);
            }

            if (groupName) {
                query.push("groupName=" + groupName);
            }
            else if (plugin.settings.groupName) {
                query.push("groupName=" + plugin.settings.groupName);
            }


            if (searchString && searchString != '') {
                query.push("searchstring=" + encodeURIComponent(searchString));
            }

            if (globalSearchString && globalSearchString != '') {
                query.push("globalsearchstring=" + encodeURIComponent(globalSearchString));
            }

            if (plugin.settings.itemId) {
                query.push("itemId=" + plugin.settings.itemId);
            }

            if (plugin.pagingInfo.isLastClick) {
                query.push("isLastClick=true");
                query.push("pagingLastCount=" + plugin.pagingInfo.pagingLastCount);
            }

            if (plugin.pagingInfo.isFirstClick) {
                query.push("isFirstClick=true");
                query.push("pagingFirstCount=" + plugin.pagingInfo.pagingFirstCount);
            }

            if (plugin.pagingInfo.isNextClick) {
                query.push("isNextClick=true");
                query.push("pagingNextCount=" + plugin.pagingInfo.pagingNextCount);
            }

            if (plugin.pagingInfo.isPreviousClick) {
                query.push("isPreviousClick=true");
                query.push("pagingPreviousCount=" + plugin.pagingInfo.pagingPreviousCount);
            }


            //for sorting
            var sortingValue = $('#custom4').val();
            if (sortingValue != null && sortingValue != undefined) {
                query.push("SortingField=" + sortingValue);

            }

            //for page size
            var pageSizeValue = $('#oldSelectedPageSize').val();
            if (pageSizeValue != null && pageSizeValue != undefined) {
                query.push("pagesize=" + pageSizeValue);

            }

            if (plugin.megaMenuFilter) {
                query.push("megaMenuFilter=true");
            }


            if ($('.allOfficeLocationddl select').val()) {
                var selectedYearVal = $('.allOfficeLocationddl select').val();
                if (selectedYearVal != "") {
                    var key = $('.allOfficeLocationddl select').find(':selected').data('category');
                    query.push(key + "=" + selectedYearVal);
                }
            }


            if ($('.allPositionddl select').val()) {
                var selectedYearVal = $('.allPositionddl select').val();
                if (selectedYearVal != "") {
                    var key = $('.allPositionddl select').find(':selected').data('category');
                    query.push(key + "=" + selectedYearVal);
                }
            }

            $('input').not("input#school-search,input#court-search,input#judge-search").each(function (index, data) {
                var filterID = $(this).attr('filter-IndexId');
                var filterName = $(this).attr('filter-IndexName');
                var filterValue = $(this).attr('filter-IndexValue');
                if (this && this.id == 'biosector-search') {
                    var practiceInput = $("#biosector-search").val();
                    if (practiceInput && $.trim(practiceInput) != '' && $.trim(practiceInput).toLowerCase() != $('#biosector-search').attr('defaultvalue').toLowerCase()) {
                        if (filterValue != null && filterValue != undefined && $.trim(practiceInput) == $.trim(filterValue)) {

                        }
                        else {
                            query.push("sectorServiceSearchString=" + $.trim(practiceInput));
                            filterID = '';
                            filterName = '';
                        }
                    }
                    else {
                        filterID = '';
                        filterName = '';
                        filterValue = '';
                    }
                }


                if (filterName == 'param_personclerkshipjudges_sm') {
                    filterID = filterID.toLowerCase();
                }
                if (filterID != null && filterID != undefined && filterName != null && filterName != undefined && filterID != '' && filterName != '') {
                    query.push(filterName + "=" + filterID);
                }
                else {

                    $(this).attr('filter-IndexId', '');
                    $(this).attr('filter-IndexName', '');
                    $(this).attr('filter-IndexValue', '');
                }
            });
            var schoolInput = $('#school-search').val();
            if (schoolInput && $.trim(schoolInput) != '') {
                var myVal = $.trim(schoolInput).toLowerCase();
                var defaultMyVal = $('#school-search').attr('defaultvalue').toLowerCase();
                var schoolFilterIndexVal = $('#school-search').attr('filter-IndexValue');
                if (myVal != defaultMyVal) {
                    if (schoolFilterIndexVal && $.trim(schoolFilterIndexVal).toLowerCase() == myVal) {
                        query.push("personschool_sm=" + myVal);
                    } else {
                        query.push("schoolsearchstring=" + myVal);
                    }
                }
            }
            var courtInput = $('#court-search').val();
            if (courtInput && $.trim(courtInput) != '') {
                var myVal = $.trim(courtInput).toLowerCase();
                var defaultMyVal = $('#court-search').attr('defaultvalue').toLowerCase();
                var courtFilterIndexVal = $('#court-search').attr('filter-IndexValue');
                if (myVal != defaultMyVal) {
                    if (courtFilterIndexVal && $.trim(courtFilterIndexVal).toLowerCase() == myVal) {
                        query.push("personclerkshipcourts_sm=" + myVal);
                    } else {
                        query.push("courtsearchstring=" + myVal);
                    }
                }
            }
            var judgeInput = $('#judge-search').val();
            if (judgeInput && $.trim(judgeInput) != '') {
                var myVal = $.trim(judgeInput).toLowerCase();
                var defaultMyVal = $('#judge-search').attr('defaultvalue').toLowerCase();
                var judgeFilterIndexVal = $('#judge-search').attr('filter-IndexValue');
                if (myVal != defaultMyVal) {
                    if (judgeFilterIndexVal && $.trim(judgeFilterIndexVal).toLowerCase() == myVal) {
                        query.push("personclerkshipjudges_sm=" + myVal);
                    } else {
                        query.push("judgesearchstring=" + myVal);
                    }
                }
            }

            return url + query.join("&"); 
        };

        plugin.init();
    };

    /* add the plugin to the jQuery.fn object */
    $.fn.peopleListing = function (options) {
        return this.each(function () {
            if (undefined == $(this).data('peopleListing')) {
                var plugin = new $.peopleListing(this, options);
                $(this).data('peopleListing', plugin);

            }
        });
    }

    $.autoSuggest = function (element, options) {
        /* defaults */
        
        var $element = $(element), element = element;

        var defaults = {
            itemId: "",
            listUrl: "/api/custom/biolisting/getautosuggestresult",
            listType: "catcompletepeople",
            languageCode: $(element).attr('data-languagecode')
        };
        var $element = $(element), element = element;
        var plugin = this;
        plugin.settings = {}

        /* init function */
        plugin.init = function () {

            plugin.settings = $.extend({}, defaults, options);
            if (plugin.settings.listType == "catcompletepeople") {
                if ($element.catcompletepeople) {
                    $element.catcompletepeople({
                        source: function (request, response) {
                            $.getJSON(plugin.settings.listUrl, { currentTime: $.now(), term: request.term, languageCode: plugin.settings.languageCode },
                                      response);
                        },
                        minLength: 2,
                        select: function (event, ui) {
                            var personURL = new URL(ui.item.PersonURL, window.location.origin);
                            personURL.searchParams.set('p', '1');
                            personURL.searchParams.set('altbio', 'true');
                            window.location = personURL.toString();
                        },
                        search: function () {
							$('ul.ui-autocomplete').scrollTop(0);
						},
                        open: function () {
							$('ul.ui-autocomplete').scrollTop(0);
						}
                    });
                }
            }

            if (plugin.settings.listType == "catcompleteGroup") {
                if ($element.catcompleteGroup) {
                    $element.catcompleteGroup({
                        source: plugin.settings.listUrl,
                        minLength: 3,
                        select: function (event, ui) {
                            $(this).val(ui.item.FilterName);
                            $(this).attr("filter-IndexId", ui.item.FilterID);
                            $(this).attr("filter-IndexName", ui.item.FilterIndexName);
                            $(this).attr("filter-IndexValue", ui.item.FilterName);
                            $(this).attr("filter-IndexParentID", ui.item.FilterParentID);
                            return false;
                        },
                        search: function () {
							$('ul.ui-autocomplete').scrollTop(0);
						},
                        open: function () {
							$('ul.ui-autocomplete').scrollTop(0);
						}
                    });

                }
            }

            if (plugin.settings.listType == "completelist") {
                if ($element.completelist) {
                    $element.completelist({
                        source: function (request, response) {
                            $.getJSON(plugin.settings.listUrl, { term: request.term, languageCode: plugin.settings.languageCode }, response);
                        },
                        minLength: 3,
                        select: function (event, ui) {
                            $(this).val(ui.item.FilterName);
                            $(this).attr("filter-IndexValue", ui.item.FilterName);
                            return false;
                        },
                        search: function () {
							$('ul.ui-autocomplete').scrollTop(0);
						},
                        open: function () {
							$('ul.ui-autocomplete').scrollTop(0);
						}
                    });

                }
            }
        }
        plugin.init();
    };

    /* add the plugin to the jQuery.fn object */
    $.fn.autoSuggest = function (options) {
        return this.each(function () {
            if (undefined == $(this).data('autoSuggest')) {
                var plugin = new $.autoSuggest(this, options);
                $(this).data('autoSuggest', plugin);
            }
        });
    }


}(jQuery));


//$('input[type="checkbox"]').removeAttr('checked');
/*Service filter*/

function checkCondition(ck_Name, ck_Id) {
    var ck_Name = ck_Name;
    var ck_Id = ck_Id;
    var checkSerLength = $('input[name="' + ck_Name + '"]:checked').length;

    if (checkSerLength == 1) {
        $(ck_Id + ' .filterSection').show();
    } else if (checkSerLength == 0) {
        $(ck_Id + ' .filterSection').hide();
    }
};



function checkFiltering(name, self) {
    //variable declaration
    var self = self;
    var ck_Name = name;
    var ck_Id = '#' + name;
    // filter box show hide
    checkCondition(ck_Name, ck_Id);

    //checked content show and unchecked content remove
    if ($(self).is(':checked')) {
        $(ck_Id + ' ul').append('<li><em>' + $(self).attr("alt") + '</em><span class="removePartFil" data-title="' + ck_Name + '"><a href="javascript:void(0);">x</a></span></li>');
    }
    else {
        $(ck_Id + ' li:contains(' + $(self).attr("alt") + ')').remove();
    }
};


function removePeopleAllFillters() {
    $('.peopleOptionChkFilters').attr('checked', false);
    $('.filterSection ul').find('li').remove();
    $('.filterSection').hide();
};


function HighlightAlphabet(data) {
    var id = 'a' + data;
    $(document).find(".alphBox ul li a").removeClass('alphaActive');
    $('#' + id).addClass('alphaActive');
};

function RemoveSelection() {
    $(document).find(".alphBox ul li a").removeClass('alphaActive');

};


/*service filter remove on click*/

function getParamValue() {
    var query = window.location.search.substring(1);
    var param = query.split('&');

    for (var i = 0; i < param.length; i++) {
        var pos = param[i].indexOf('=');
        if (pos > 0) {
            var key = param[i].substring(0, pos);
            var val = param[i].substring(pos + 1);
        }
    }


}

function getParamValueFrmKey(keyVal) {
    var query = window.location.search.substring(1);
    var param = query.split('&');

    for (var i = 0; i < param.length; i++) {
        var pos = param[i].indexOf('=');
        if (pos > 0) {
            var key = param[i].substring(0, pos);
            if (keyVal == key)
                return param[i].substring(pos + 1);

        }
    }
    return null;


}


